export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [2,8];

export const dictionary = {
		"/(admin)/(dashboard)": [~9,[2]],
		"/(admin)/(global)/artificial": [~10,[2]],
		"/(admin)/emc/capacity": [~14,[2,4]],
		"/(admin)/emc/imbalance": [~15,[2,4]],
		"/(admin)/emc/performance": [~16,[2,4]],
		"/(admin)/emc/vmware": [~17,[2,4]],
		"/(admin)/hp/(events)/fe-port-events": [~18,[2,5]],
		"/(admin)/hp/(global-statistics)/imbalances": [~21,[2,6]],
		"/(admin)/hp/(global-statistics)/logical-capacity": [~22,[2,6]],
		"/(admin)/hp/(global-statistics)/performance": [~23,[2,6]],
		"/(admin)/hp/(events)/pg-events": [~19,[2,5]],
		"/(admin)/hp/(global-statistics)/physical-capacity": [~24,[2,6]],
		"/(admin)/hp/(events)/sla": [~20,[2,5]],
		"/(admin)/hp/(global-statistics)/vmware-capacity": [~25,[2,6]],
		"/(admin)/(global)/maintainers": [~11,[2]],
		"/(admin)/netapp/aggregateCapacity": [~26,[2]],
		"/(admin)/netapp/qtrees": [27,[2]],
		"/(admin)/netapp/qtrees/public": [28,[2]],
		"/(admin)/netapp/sharesAndSnapshots": [~29,[2]],
		"/(admin)/netapp/snapMirrorHealth": [~30,[2]],
		"/(admin)/netapp/volumeCapacity": [~31,[2]],
		"/(admin)/overview/[system]/ldev-heatmap": [~33,[2,,8],[,7]],
		"/(admin)/overview/[system]/[board]": [32,[2,,8],[,7]],
		"/(admin)/(global)/storage-config": [~12,[2,3]],
		"/(admin)/(global)/storage-config/[id]": [~13,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';